body {
    font-family: 'Courier New', cursive, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  button {
    transition: all 0.3s ease-in-out;
  }
  
  button:hover {
    transform: scale(1.1);
  }
  